<template>
  <div class="form-payment bg-white" id="payment--success">
    <header class="sticky-top border border-light-bottom border-light-line border-light-top border-light-line p-2">
      <b-row>
        <b-col cols="12">
          <h1 class="text-dark h6 font-weight-bolder mb-0 text-center">
            Pembayaran Berhasil
          </h1>
        </b-col>
        <b-col cols="3" />
      </b-row>
    </header>
    <!-- <div class="text-center mt-3"> -->
      <!-- <h6 class="text-dark text-darken-4 fw-bold-800 mb-1">
        Total Harga
      </h6> -->
      <!-- <h6 class="text-dark text-darken-4 size12 fw-bold-800">
        Sudah di potong diskon 230.000
      </h6> -->
    <!-- </div> -->
    <b-container class="mt-5 pt-5">
      <b-row class="justify-content-center">
        <b-col cols="5" class="text-center">
          <h5
            class="text-dark fw-bold-700 mb-2 size24"
            style="line-height: 40px;"
          >
            Telah diterima dan berhasil untuk pembayaran {{ summaryTotal() | formatAmount }}
          </h5>
        </b-col>
      </b-row>
      <b-row class="justify-content-center">
        <b-col cols="4" class="text-center">
          <div class="bg-light bg-background-2 py-2 border-12 size16 text-dark fw-bold-800 mb-3">
            Kembalian pembayaran {{ parseFloat($route.query.cash_change) | formatAmount }}
          </div>
        </b-col>
      </b-row>
      <b-row class="justify-content-center mb-5">
        <b-col
          cols="5"
          class="text-center"
        >
          <b-img
            width="196"
            :src="require('@/assets/images/illustration_success.png')"
          />
        </b-col>
      </b-row>
      <b-row class="justify-content-center">
        <b-col
          cols="5"
          class="text-center"
        >
          <div class="d-flex justify-content-between">
            <b-button class="bg-white btn-block mr-2 py-2 border-8" @click="prevPage">
              Buat Transaksi Baru
            </b-button>
            <b-button class="mt-0 py-2 border-8 btn-block" @click="invoice">
              Cetak Struk
            </b-button>
          </div>
        </b-col>
      </b-row>
    </b-container>
  </div>
</template>

<script>
import {
  BContainer, BRow, BCol, VBToggle, BButton, BImg,
} from 'bootstrap-vue'
import { preformatFloat } from '@/auth/utils'

export default {
  title() {
    return 'Pembayaran Berhasil'
  },
  components: {
    BContainer,
    BRow,
    BCol,
    BButton,
    BImg,
  },
  setup() {
    return {
      preformatFloat
    }
  },
  directives: {
    'b-toggle': VBToggle,
  },
  data() {
    return {
    }
  },
  mounted() {
    localStorage.removeItem('payloadDelivery')
  },
  methods: {
    summaryTotal() {
      if (this.$route.query.type_payment == 0) {
        return parseFloat(preformatFloat(this.$route.query.amount)) || 0
      }
      return parseFloat(this.$route.query.total)
    },
    prevPage() {
      this.$router.push({ name: 'cashier.index', query: { type: 'guest' } })
      // window.location.href = '/'
    },
    invoice() {
      this.$router.push({ name: 'invoice.index', params: { id: this.$route.query.uuid } })
    },
  },
}
</script>

<style lang="scss">
@import '@/assets/scss/variables/_variables.scss';

button.bg-white {
  background-color: #FFFFFF !important;
  color: $dark !important;
  border: 1px solid $light--3 !important;
  font-weight: 800;
}
</style>

<style lang="scss" scoped>

body {
  background: #FFFFFF;
}

.form-payment {
  height: 100vh;

  .custom__form--input mb-0 {
    label {
      margin-bottom: 1rem !important;
    }
  }

  .image-box {
    width: 46px;
    height: 46px;
  }
  .card {
    box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.04);
    border-radius: 12px;
  }
}
</style>
